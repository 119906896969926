import React from 'react';

import Hero from '../components/hero/story/HeroStory';
import Action from '../components/action/Action';
import Offer from '../components/collaborate/offer/Offer';
import Ask from '../components/collaborate/ask/Ask';
import Footer from '../components/footer/Footer';

import partner_image from '../resources/hero/partner_image.png';
import insider_image from '../resources/hero/insider_image.png';

export default ({ type }) => {
    return (
        <>
            <Hero 
                pillText="We invite you to become a..."
                title={`Nomadity ${type}`}
                subtitle={<>
                    Embrace the freedom of the digital nomad lifestyle with Nomadity.
                    <br />
                    Your go-to platform for exploration, connectivity and collaboration.
                </>}
                image={type === "Insider" ? insider_image : partner_image}
            />
            <Action 
                body={type === "Insider" ? <>
                    Become a <strong>Nomadity Insider</strong> to step into a world where work and play merge seamlessly.
                </> : <>
                    Become a <strong>Nomadity Partner</strong> and step into a world where every destination is a meeting point for growth and opportunity.
                </>}
                showButton={false}
            />
            <Offer type={type} />
            <Ask type={type} />
            <Action
                title="Join the tribe"
                body={type === "Insider" ? <>
                    We're excited to co-create the Nomadity app with you.
                    <br /> <br />
                    Your insights, passion, and creativity can help us innovate and offer immense value to every user.
                    <br /> <br />
                    Together, let's re-invent the digital nomad lifestyle!
                </> : <>
                    <strong>Nomadity Partners</strong> are not just collaborators - they are co-creators of an ecosystem where innovation, community, and business converge for mutual growth.
                    <br /> <br />
                    Together, let's re-invent the digital nomad lifestyle!
                </>}
                showButton={true}
            />
            <Footer />
        </>
    )
};