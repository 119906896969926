import React from 'react';

import Hero from '../components/hero/story/HeroStory';
import Block from '../components/block/Block';
import Members from '../components/members/Members';
import Action from '../components/action/Action';
import Footer from '../components/footer/Footer';

import heroImage from '../resources/hero/about_image.png';

export default () => {
    return (
        <div>
            <Hero 
                title={["Our journey"]}
                subtitle={<>
                    We began with a simple quest: living the authentic digital nomad life.
                    <br /> <br />
                    The captivating Mediterranean coast beckoned. But amind the beauty and adventure, a challenge arose: how do digital nomads truly connect?
                    <br /> <br />
                    Inspired by the challenges faced while travelling, the idea of <strong>Nomadity</strong> was born.
                </>}
                image={heroImage}
            />
            {/* <Block 
                title={<><strong>Nomadity</strong> is an app built by digital nomads, for digital nomads</>}
                showLogos={false}
            /> */}
            <Action 
                title={"Re-defining the lifestyle"}
                body={"We’re a team bound by a shared dream - to revolutionise the way digital nomads connect. With a mix of diverse skills and experiences, we’re crafting an app that understands its users because we are its users."}
                showButton={false}
            />
            <Members />
            
            <Footer />
        </div>
    );
}