import React from 'react';
import './Footer.css';
import { useNavigate } from 'react-router-dom';

import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import logo from '../../resources/logo_icon.png';

function Footer() {
    const navigate = useNavigate();

    return (
        <div className="footer-section">
            <div className="footer-container">
                <div className="footer-column footer-left">
                        <div className="footer-content-left-copyright hide-on-mobile">
                            © 2023 Infinitus Interactive. All Rights Reserved.
                        </div>
                    </div>
                <div className="footer-column footer-center">
                    <div className="social-icons">
                        <div className="button-wrapper">
                            <a href="https://www.instagram.com/nomadity_app/" target="_blank" rel="noopener noreferrer">
                                <InstagramIcon fontSize="large" sx={{ color: 'white' }} />
                            </a>
                        </div>
                        <div className="button-wrapper">
                            <a href="https://www.linkedin.com/company/nomadity-app/" target="_blank" rel="noopener noreferrer">
                                <LinkedInIcon fontSize="large" sx={{ color: 'white' }} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-column footer-right">
                    <button className="navbar-button" onClick={() => navigate('/')}>
                        <div className="button-wrapper">
                            <div className="logo-wrapper">
                                <div className="logo">
                                    <img className="logo-icon" src={logo} alt=""/>
                                    <div className="logo-text">Nomadity</div>
                                </div>
                            </div>
                        </div>
                    </button>
                </div>
                <div className="hide-on-desktop">
                    <div className="footer-column footer-left">
                        <div className="footer-content-left-copyright">
                            © 2023 Infinitus Interactive. All Rights Reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
