import React from 'react';
import '../Hero.css';
import './HeroStory.css';

function Hero({ pillText, title, subtitle, image}) {
    return (
        <div className="hero">
            <div className="hero-container">
                <div className="hero-title-section">
                    {pillText && 
                        <div className="hero-pill">
                            <div className="hero-pill-text">{pillText}</div>
                        </div>
                    }
                    <div className="hero-title-content">
                        <div className="hero-title-main-text">
                            {title}
                        </div>
                        <div className="hero-paragraph-wrapper-story">
                            <div className="hero-paragraph-text">
                                {subtitle}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-image-container">
                    <img src={image} alt="Hero" className="hero-image" />
                </div>
            </div>
        </div>
    );
}

export default Hero;
