import React from 'react';
import './Features.css';

import Card from '../card/large/CardLarge';

import icon_discovery from '../../resources/icons/icon_discovery.png';
import icon_connection from '../../resources/icons/icon_connection.png';
import icon_community from '../../resources/icons/icon_community.png';
import discovery_image_1 from '../../resources/features/card_images/card_discovery/discovery_1.png';
import discovery_image_2 from '../../resources/features/card_images/card_discovery/discovery_2.png';
import discovery_image_3 from '../../resources/features/card_images/card_discovery/discovery_3.png';
import connection_image_1 from '../../resources/features/card_images/card_connection/connection_1.png';
import connection_image_2 from '../../resources/features/card_images/card_connection/connection_2.png';
import connection_image_3 from '../../resources/features/card_images/card_connection/connection_3.png';
import community_image_1 from '../../resources/features/card_images/card_community/community_1.png';
import community_image_2 from '../../resources/features/card_images/card_community/community_2.png';
import community_image_3 from '../../resources/features/card_images/card_community/community_3.png';

function Features() {
    return (
        <div id="features" className="feature-container">
            <div className="feature-content">
                <div className="feature-title-section">
                    <div className="feature-title-wrapper">
                        <div className="feature-title">
                            The new era of work and travel
                        </div>
                    </div>
                    <div className="feature-subtitle">
                        {<>Your nomadic journey begins with Nomadity</>}
                    </div>
                </div>
                <div className="feature-container-content">
                    <Card 
                        icon={icon_discovery}
                        shadowColour={'blue'}
                        title="Discovery"
                        content={<>
                            Unearth hidden gems and tailored recommendations as you traverse the globe. From cosy cafes to tranquil retreats, our <strong>Discovery</strong> feature illuminates the path less travelled.
                            <br /> <br />
                            <strong>Personalised Recommendations</strong>
                            <br />
                            Tailored picks based on your interests and past activities.
                            <br /> <br />
                            <strong>Interactive Maps</strong>
                            <br />
                            Find the best spots in a city, all visually laid out ot be easily accessible.
                            <br /> <br />
                            <strong>Community Ratings & Reviews</strong>
                            <br />
                            Trustworthy insights from fellow digital nomads.
                            <br /> <br />
                            <strong>Bookmark & Plan</strong>
                            <br />
                            Save must-visit spots for your upcoming adventures.
                            <br /> <br />
                            <strong>Local Experiences</strong>
                            <br />
                            Explore exclusive experiences and activities for the digital nomad.
                        </>}
                        images={[discovery_image_1, discovery_image_2, discovery_image_3]}
                        multipleImages={true}
                    />
                    <Card
                        icon={icon_connection}
                        shadowColour={'purple'}
                        title="Connection"
                        content={<>
                            Forge meaningful connections with fellow digital nomads. Whether you are looking for a co-working buddy, a travel companion, or just someone who understands the nomad life, our <strong>Connection</strong> feature brings you closer.
                            <br /> <br />
                            <strong>Personalised Matches</strong>
                            <br />
                            Algorithm-driven suggestions for compatible digital nomads.
                            <br /> <br />
                            <strong>Safe & Secure</strong>
                            <br />
                            Chat with privacy; control what you share.
                            <br /> <br />
                            <strong>Direct Communication Tools</strong>
                            <br />
                            Once matched, seamlessly message or initiate phone and video calls for deeper connections.
                            <br /> <br />
                            <strong>Feedback & Ratings</strong>
                            <br />
                            Ensure quality interactions and genuine connections.
                            <br /> <br />
                            <strong>Multiple Modes</strong>
                            <br />
                            Connect based on professional skills, hobbies, and travel plans.
                        </>}
                        images={[connection_image_1, connection_image_2, connection_image_3]}
                        multipleImages={true}
                    />
                    <Card 
                        icon={icon_community}
                        shadowColour={'pink'}
                        title="Community"
                        content={<>
                            Dive into a dynamic world where digital nomads connect. From discussions to the best co-working spaces and events to a local guide for your next destination, our <strong>Community</strong> feature is the beating heart of Nomadity.
                            <br /> <br />
                            <strong>Real-time Discussions</strong>
                            <br />
                            Engage in vibrant conversations with fellow digital nomads
                            <br /> <br />
                            <strong>Topic Channels</strong>
                            <br />
                            Filter discussions by interests, destinations, tips, and more.
                            <br /> <br />
                            <strong>Local Guides</strong>
                            <br />
                            Find and connect with experienced digital nomads in your next destination.
                            <br /> <br />
                            <strong>Events & Meetups</strong>
                            <br />
                            Discover and join nomadic events happening around you.
                            <br /> <br />
                            <strong>User Profiles</strong>
                            <br />
                            Get to know your fellow community members, their journey, and expertise.
                        </>}
                        images={[community_image_1, community_image_2, community_image_3]}
                        multipleImages={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default Features;
