import React from 'react';

import Hero from '../components/hero/Hero'; 
import Block from '../components/block/Block';
import Features from '../components/features/Features';
import Action from '../components/action/Action';
import Footer from '../components/footer/Footer';

export default () => {
    return (
      <div>
        <Hero />
        <Block
          title="Partnered with top influences of the digital nomad world" 
          showLogos={true}
        />
        <Features />
        <Action
          title="Join the tribe"
          body={<>Become a <strong>Nomadity Insider</strong> or <strong>Nomadity Partner</strong> today</>}
          showButton={true}
        />
        <Footer />
      </div>
    );
  }