import React, { useEffect, useRef } from 'react';
import './Hero.css';

import Typed from "typed.js";

import CountdownTimer from '../countdown/Countdown';

import heroImage from '../../resources/hero/home_image.png';

function Hero() {
    const typedJSRef = useRef(null);

    useEffect(() => {
        const typedJS = new Typed(typedJSRef.current, {
            strings: ["Meet digital nomads", "Discover new locations", "Grow your network", "Build a community",],
            typeSpeed: 70,
            backSpeed: 70,
            backDelay: 700,
            startDelay: 500,
            loop: true,
        });

        return () => typedJS.destroy();
    }, []);

    return (
        <div className="hero">
            <div className="hero-container">
                <div className="hero-title-section">
                    <div className="hero-pill">
                        <div className="hero-pill-text">Your digital nomad companion</div>
                    </div>
                    <div className="hero-title-content">
                        <div className="hero-title-main-text">
                            <span ref={typedJSRef} />
                        </div>
                        <div className="hero-paragraph-wrapper">
                            <div className="hero-paragraph-text">
                                Step into a world where work and play merge seamlessly
                            </div>
                        </div>
                    </div>
                    {/* <div className="hero-actions">
                        <button className="button-filled"><CountdownTimer /></button>
                        <div className="button-wrapper">
                            <button className="button-filled">Connect with us</button>
                        </div>
                    </div> */}
                </div>
                <div className="hero-image-container">
                    <img src={heroImage} alt="Hero" className="hero-image" />
                </div>
            </div>
        </div>
    );
}

export default Hero;
