import React from 'react';
import './Ask.css';

import Card from '../../card/small/CardSmall';

import icon_ceo from '../../../resources/icons/icon_ceo.png';
import icon_cmo from '../../../resources/icons/icon_cmo.png';
import icon_cfo from '../../../resources/icons/icon_cfo.png';

// Nomadity Insiders
import icon_lightbulb from '../../../resources/icons/icon_lightbulb.png';
import icon_chart from '../../../resources/icons/icon_chart.png';
import icon_chat from '../../../resources/icons/icon_chat.png';
import icon_pencil from '../../../resources/icons/icon_pencil.png';
import icon_thumb from '../../../resources/icons/icon_thumb.png';
import icon_coffee from '../../../resources/icons/icon_coffee.png';

// Nomadity Partners
import icon_handshake from '../../../resources/icons/icon_handshake.png';
import icon_cloud from '../../../resources/icons/icon_cloud.png';
import icon_chess from '../../../resources/icons/icon_chess.png';
import icon_jigsaw from '../../../resources/icons/icon_jigsaw_2.png';

function Ask({ type }) {
    return (
        <div className="ask-container">
            <div className="feature-title-section">
                <div className="feature-title-wrapper">
                    <div className="feature-title">
                        {type === "Insider" && <>
                        What ask from you
                        </>}
                        {type === "Partner" && <>
                        Our expectations
                        </>}
                    </div>
                </div>
            </div>
            <div className="ask-content">
                <div className="ask-container-content">
                    {type === "Insider" && <>
                        <Card 
                            icon={icon_lightbulb}
                            shadowColour={'pink'}
                            title="Feedback"
                            content="Share helpful insights to improve the app"
                        />
                        <Card
                            icon={icon_chart}
                            shadowColour={'blue'}
                            title="Advocacy"
                            content="Spread the word & grow our community"
                        />
                        <Card
                            icon={icon_chat}
                            shadowColour={'purple'}
                            title="Engagement"
                            content="Stay active in forums & discussions to assist new users"
                        />
                        <Card
                            icon={icon_pencil}
                            shadowColour={'pink'}
                            title="Content"
                            content="Engage & educate the community about app features & benefits"
                        />
                        <Card
                            icon={icon_thumb}
                            shadowColour={'blue'}
                            title="Marketing"
                            content="Promote the app throughout social media platforms"
                        />
                        <Card
                            icon={icon_coffee}
                            shadowColour={'purple'}
                            title="Ambassadorship"
                            content="Represent the app at events & present value in forums"
                        />
                    </>}
                    {type === "Partner" && <>
                        <Card 
                            icon={icon_lightbulb}
                            shadowColour={'pink'}
                            title="Feedback"
                            content="Share helpful insights to help refine and enhance the business features and tools"
                        />
                        <Card
                            icon={icon_handshake}
                            shadowColour={'blue'}
                            title="Ambassadorship"
                            content="Promote Nomadity within your network, showcasing our features and benefits"
                        />
                        <Card
                            icon={icon_cloud}
                            shadowColour={'purple'}
                            title="Data sharing"
                            content="Allow us to gather insights from usage patterns to optimise business features"
                        />
                        <Card
                            icon={icon_pencil}
                            shadowColour={'pink'}
                            title="Content"
                            content="Create insightful and engaging content to highlight the synergy between our brands and global offerings"
                        />
                        <Card
                            icon={icon_chess}
                            shadowColour={'blue'}
                            title="Strategy"
                            content="Assist in broadening Nomadity's reach by integrating our platform into your marketing activities"
                        />
                        <Card
                            icon={icon_jigsaw}
                            shadowColour={'purple'}
                            title="Integration"
                            content="Align your services with Nomadity to create a seamless user experience"
                        />
                    </>}
                </div>
            </div>
        </div>
    );
}

export default Ask;
