import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

import CountdownTimer from '../countdown/Countdown';

import logo from '../../resources/logo_icon.png';

function Navbar() {
    const navigate = useNavigate();

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="navbar-content">
                    <div className="navbar-brand">
                        <button className="navbar-button" onClick={() => navigate('/')}>
                            <div className="button-wrapper">
                                <div className="logo-wrapper">
                                    <div className="logo">
                                        <img className="logo-icon" src={logo} alt=""/>
                                        <div className="logo-text">Nomadity</div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                    <div className="navbar-menu">
                        <button className="navbar-button" onClick={() => navigate('/')}>
                            <div className="button-wrapper">
                                <div className="navbar-link-text">Home</div>
                            </div>
                        </button>
                        <button className="navbar-button" onClick={() => navigate('/about')}>
                            <div className="button-wrapper">
                                <div className="navbar-link-text">About</div>
                            </div>
                        </button>
                        <button className="navbar-button hide-on-desktop" onClick={() => navigate('/connect')}>
                            <div className="button-wrapper">
                                <div className="navbar-link-text">Connect</div>
                            </div>
                        </button>
                        {/* <button className="button-filled hide-on-mobile"><CountdownTimer /></button> */}
                        <div className="button-wrapper hide-on-mobile">
                            <button className="button-filled" onClick={() => navigate('/connect')}>Connect with us</button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
