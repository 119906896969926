import React from 'react';
import './Block.css';

import LogoWrapper from '../logo-wrapper/LogoWrapper';

import partner_infinitus from '../../resources/partners/partner_infinitus.png';

function Block({ title, showLogos }) {
    return (
        <div className="block-container">
            <div className="block-title">
                {title}
            </div>
            {showLogos && <div className="block-content">
                <div className="block-logos">
                    <LogoWrapper logoSrc={partner_infinitus} width="180px" height="40px" />
                </div>
            </div>}
        </div>
    );
}

export default Block;
