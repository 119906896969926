import React from 'react';
import './Offer.css';

import Card from '../../card/small/CardSmall';

import icon_ceo from '../../../resources/icons/icon_ceo.png';
import icon_cmo from '../../../resources/icons/icon_cmo.png';

// Nomadity Insider
import icon_beta from '../../../resources/icons/icon_beta.png';
import icon_heart from '../../../resources/icons/icon_heart.png';
import icon_jigsaw from '../../../resources/icons/icon_jigsaw.png';
import icon_handshake from '../../../resources/icons/icon_handshake.png';
import icon_pizza from '../../../resources/icons/icon_pizza.png';
import icon_badge from '../../../resources/icons/icon_badge.png';
import icon_shirt from '../../../resources/icons/icon_shirt.png';
import icon_paint from '../../../resources/icons/icon_paint.png';

// Nomadity Partner
import icon_shop from '../../../resources/icons/icon_shop.png';
import icon_globe from '../../../resources/icons/icon_globe.png';
import icon_support from '../../../resources/icons/icon_support.png';
import icon_influence from '../../../resources/icons/icon_influence.png';

function Offer({ type }) {
    return (
        <div className="offer-container">
            <div className="feature-title-section">
                <div className="feature-title-wrapper">
                    <div className="feature-title">
                        {type === "Insider" && <>
                        What we can offer you
                        </>}
                        {type === "Partner" && <>
                        Exclusive benefits
                        </>}
                    </div>
                </div>
            </div>
            <div className="offer-content">
                <div className="offer-container-content">
                    {type === "Insider" && <>
                        <Card 
                            icon={icon_beta}
                            shadowColour={'blue'}
                            title="Beta access"
                            content="Experience new features before anyone else"
                        />
                        <Card
                            icon={icon_heart}
                            shadowColour={'purple'}
                            title="Join the tribe"
                            content="Network with other innovators & early adopters"
                        />
                        <Card
                            icon={icon_jigsaw}
                            shadowColour={'pink'}
                            title="Participation"
                            content="Directly influence feature development & improvements"
                        />
                        <Card
                            icon={icon_handshake}
                            shadowColour={'blue'}
                            title="Meet the team"
                            content="Get exclusive invites to meet the creators of Nomadity"
                        />
                        <Card
                            icon={icon_pizza}
                            shadowColour={'purple'}
                            title="Lifetime premium"
                            content="Unlock endless premium features upon release"
                        />
                        <Card
                            icon={icon_badge}
                            shadowColour={'pink'}
                            title="Official status"
                            content="Stand out with recognition badges & mentions"
                        />
                        <Card
                            icon={icon_shirt}
                            shadowColour={'blue'}
                            title="Merchandise"
                            content="Receive branded items to show your early supporter status"
                        />
                        <Card
                            icon={icon_paint}
                            shadowColour={'purple'}
                            title="Personalised app"
                            content="Enjoy custom themes & exclusive emojis"
                        />
                    </>}
                    {type === "Partner" && <>
                        <Card 
                            icon={icon_ceo}
                            shadowColour={'blue'}
                            title="Early access"
                            content="First to explore and utilise our innovative business tools"
                        />
                        <Card
                            icon={icon_cmo}
                            shadowColour={'purple'}
                            title="Promotions"
                            content="Feature in Nomadity's marketing campaigns"
                        />
                        <Card
                            icon={icon_shop}
                            shadowColour={'pink'}
                            title="Business profile"
                            content="Unlock enhanced profile features, analytics, and insights"
                        />
                        <Card
                            icon={icon_globe}
                            shadowColour={'blue'}
                            title="Expansion"
                            content="Connect with a global community of nomads"
                        />
                        <Card
                            icon={icon_pizza}
                            shadowColour={'purple'}
                            title="Lifetime premium"
                            content="Unlock endless premium features upon release"
                        />
                        <Card
                            icon={icon_badge}
                            shadowColour={'pink'}
                            title="Official status"
                            content="Stand out with recognition badges & mentions"
                        />
                        <Card
                            icon={icon_support}
                            shadowColour={'blue'}
                            title="Support"
                            content="Access swift and efficient support channels"
                        />
                        <Card
                            icon={icon_influence}
                            shadowColour={'purple'}
                            title="Influence"
                            content="Directly impact feature development and improvements"
                        />
                    </>}
                </div>
            </div>
        </div>
    );
}

export default Offer;
