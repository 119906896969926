import React from 'react';
import './CardSmall.css';

export default ({ icon, shadowColour, title, content }) => {
    const shadowClass = shadowColour === 'blue' ? 'blue-shadow' :
                        shadowColour === 'purple' ? 'purple-shadow' :
                        shadowColour === 'pink' ? 'pink-shadow' : '';
    return (
        <div className="card-small-container">
            <div className="card-small-content">
                <div className="card-small-content-icon-wrapper">
                    <img className={`card-small-content-icon-image ${shadowClass}`} src={icon}/>
                </div>
                <div className="card-small-content-card-small-content">
                    <div className="card-small-content-title">{title}</div>
                    <div className="card-small-content-text">{content}</div>
                </div>
            </div>
        </div>
    )
}
