import React, { useState, useRef } from 'react';
import './Form.css';
import emailjs from '@emailjs/browser';

emailjs.init(process.env.REACT_APP_PUBLIC_KEY)

export default () => {
    const form = useRef();
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            form.current,
            process.env.REACT_APP_PUBLIC_KEY
        ).then((result) => {
            console.log(result.text);
            setSubmitted(true);
        }, (error) => {
            console.log(error.text);
        });
    };

    if (submitted) {
        return (
            <div className="title-section">
                <div className="title-content">
                    <div className="title-main-text">Connected!</div>
                </div>
                <div className="paragraph-wrapper">
                    <div className="paragraph-text">{<>
                        A member of our team will get back to you soon. ✌️
                    </>}</div>
                </div>
            </div>
        )
    }

    return (
        <div className="contact-form-container">
            <div className="contact-form">
                <div className="form-container">
                    <div className="form-content">
                        <div className="form-content-sub-content">
                            <div className="form-content-title">Connect with us</div>
                            <form ref={form} onSubmit={handleSubmit}>
                                <div className="form-content-container">
                                    <div className="form-content-top-row">
                                        <input type="text" name="name" placeholder="Name" required />
                                        <input type="text" name="email_address" placeholder="Email address" required />
                                    </div>
                                    <textarea name="message" placeholder="Your message" required />
                                </div>
                                <button className="button-outlined button-wrapper" type="submit">Send</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
