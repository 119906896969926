import React from 'react';
import './Contact.css';

import Form from '../card/form/Form';

function Contact() {
    return (
        <div className="contact">
            <div className="contact-container">
                <div className="contact-title-section">
                    <div className="contact-pill">
                        <div className="contact-pill-text">Join the <strong>Nomadity</strong> tribe today</div>
                    </div>
                    <div className="contact-title-content">
                        {/* <div className="title-main-text">
                            Join the tribe
                        </div> */}
                        <div className="contact-paragraph-wrapper-contact">
                            <div className="contact-paragraph-text">
                                Whether you would like to become a collaborator, or just get in touch - we are all ears!
                            </div>
                        </div>
                    </div>
                </div>
                <Form />
            </div>
        </div>
    );
}

export default Contact;
