import React from 'react';
import './Action.css';
import { useNavigate } from 'react-router-dom';

function Action({ title, body, showButton }) {
    const navigate = useNavigate();

    return (
        <section className="action-section">
            <div className="action-container">
                <div className="action-content">
                    {title && <div className="action-content-title">{title}</div>}
                    {body && <div className="action-content-body">{body}</div>}
                    {showButton && 
                        <div className="button-wrapper">
                            <button className="button-outlined navbar-link-button" onClick={() => navigate('/connect')}>Connect with us</button>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
}

export default Action;
