import React from 'react';
import './Members.css';

import Card from '../card/large/CardLarge';

import icon_ceo from '../../resources/icons/icon_ceo.png';
import icon_cmo from '../../resources/icons/icon_cmo.png';
import icon_cfo from '../../resources/icons/icon_cfo.png';
import member_dylan from '../../resources/team/dylan.jpg';
import member_coco from '../../resources/team/coco.jpg';
import member_richard from '../../resources/team/richard.jpg';

function Members() {
    return (
        <div className="members-container">
            <div className="members-content">
                <div className="members-title-section">
                    <div className="members-title-wrapper">
                        <div className="members-title">
                            The minds behind Nomadity
                        </div>
                    </div>
                    <div className="members-subtitle">
                        {<>Meet the passionate people bringing Nomadity to life</>}
                    </div>
                </div>
                <div className="members-container-content">
                    <Card 
                        icon={icon_ceo}
                        shadowColour={'blue'}
                        title="The Visionary"
                        content={<>
                            <strong>Dylan Black</strong>
                            <br />
                            Co-Founder & CEO/CTO
                            <br /> <br />
                            Initiated the Nomadity dream while exploring the Mediterranean coast. With years of app development experience and an underlying passion for the nomadic lifestyle, Dylan is the driving force behind the app.
                            <br /> <br />
                            Successfully managed two start-up companies, currently the lead developer of a ground-breaking digital twin organisation and has run a thriving software-as-a-service company for the past three years.
                            </>}
                        images={[member_dylan]}
                        multipleImages={false}
                    />
                    <Card
                        icon={icon_cmo}
                        shadowColour={'purple'}
                        title="The Marketing Maverick"
                        content={<>
                            <strong>Coco Gatherum</strong>
                            <br />
                            Co-Founder & CMO
                            <br /> <br />
                            Crafting the story of Nomadity and ensuring it reaches every corner of the world. With a background in social media marketing and influencing for household brands, Coco brings a unique blend of creativity and strategy to the table.
                            <br /> <br />
                            The bridge between Nomadity and its users. Coco ensures feedback flows, community grows, and Nomadity remains an app for the users, by the users.
                            </>}
                        images={[member_coco]}
                        multipleImages={false}
                    />
                    <Card 
                        icon={icon_cfo}
                        shadowColour={'pink'}
                        title="The Number Navigator"
                        content={<>
                            <strong>Richard Avila</strong>
                            <br />
                            Co-Founder & CFO
                            <br /> <br />
                            Steering Nomadity through fiscal currents with precision, Richard brings a wealth of experience as a seasoned finance and accounting consultant. With a track record spanning various businesses, from start-ups to global corporations, he has consistently guided them towards their financial objectives.
                            <br /> <br />
                            Richard crafts and fine-tunes our financial symphony, ensuring harmony in our growth and sustainability.
                            </>}
                        images={[member_richard]}
                        multipleImages={false}
                    />
                </div>
            </div>
        </div>
    );
}

export default Members;
