import React from 'react';
import './CardLarge.css';

export default ({ icon, shadowColour, title, content, images, multipleImages }) => {
    const shadowClass = shadowColour === 'blue' ? 'blue-shadow' :
                        shadowColour === 'purple' ? 'purple-shadow' :
                        shadowColour === 'pink' ? 'pink-shadow' : '';
    return (
        <div className="card-container">
            <div className="card-content-left">
                <div className="card-content-icon-wrapper">
                    <img className={`card-content-icon-image ${shadowClass}`} src={icon}/>
                </div>
                <div className="card-content-left-card-content">
                    <div className="card-content-title">{title}</div>
                    <div className="card-content-text">{content}</div>
                </div>
            </div>
            <div className="card-content-right">
                {multipleImages ? <>
                    <div className="card-image" style={{backgroundImage: `url(${images[0]})`}}></div>
                    <div className="card-image card-image-2" style={{backgroundImage: `url(${images[1]})`}}></div>
                    <div className="card-image card-image-3" style={{backgroundImage: `url(${images[2]})`}}></div>
                </> : <>
                    <div className="card-portrait" style={{backgroundImage: `url(${images[0]})`}}></div>
                </>}
            </div>
        </div>
    )
}
