import React from 'react';
import './LogoWrapper.css';

function LogoWrapper({ logoSrc, width, height }) {
    return (
        <div className="logo-wrapper">
            <div className="logo-container">
                <img src={logoSrc} alt="Partner Logo" className="logo-image" style={{ width, height }} />
            </div>
        </div>
    );
}

export default LogoWrapper;
